<template>
  <v-main>
  <v-container>
    <v-container style="height: 2vh"></v-container>
    <v-row>
      <v-col cols="3">
        <time-picker label="開始日" @on-ok-click="startDate = $event" @on-clear-click="startDate = null" />
      </v-col>
      <v-col cols="3">
        <time-picker label="終了日" @on-ok-click="endDate = $event" @on-clear-click="endDate = null" />
      </v-col>
      <v-col>
        <v-btn @click="onSearchClick" color="primary" depressed>
          検索
        </v-btn>
      </v-col>
      <v-col></v-col>
      <v-col>
        <vue-json-to-csv id="download-btn" :json-data="records" csv-title="rice-scouter-records.csv" >CSVダウンロード</vue-json-to-csv>
      </v-col>
    </v-row>
    <div>
      <v-data-table 
        id="rs-table"
        flat 
        :headers="headers" 
        :items="records" 
        :items-per-page="10" 
        :calculate-widths="true" 
        :fixed-header="true"
        @click:row="onRecordClick" />
      <DataDetail :record="selectedRecord" :showDataDetail="showDataDetail" @close-data-detail="closeDataDetail" />
    </div>
  <log-out-confirm :title="logoutTitle" :showLogOutConfirm="showLogOutConfirm" @close-logout-confirm="closeLogOutConfirm" />

  <!-- alert on snackbar -->
  <v-snackbar
    v-model="showError"
    top
  >
    {{ errorText }}
  </v-snackbar>

  </v-container>
  </v-main>
</template>

<script>
// import { GChart } from 'vue-google-charts'
import axios from "axios"
import store from "@/store"
import TimePicker from '@/components/TimePicker.vue'
import DataDetail from '@/components/DataDetail.vue'
import VueJsonToCsv from 'vue-json-to-csv'
import LogOutConfirm from "@/components/LogOutConfirm"

export default {
  components: {
    TimePicker,
    DataDetail,
    VueJsonToCsv,
    LogOutConfirm
    // GChart
  },
  name: "Table",
  data() {
    return {
      startDate: "",
      endDate: "",
      records: [],
      showDataDetail: false,
      selectedRecord: null,
      headers: [
        {text: "タイプ", sortable: false, value: "type"},
        {text: "ラベル", sortable: true, value: "label"},
        {text: "結果 (g/m2)", sortable: true, value: "result"},
        {text: "信頼度", sortable: true, value: "confidence"},
        {text: "緯度", sortable: true, value: "latitude"},
        {text: "経度", sortable: true, value: "longitude"},
        {text: "取得時刻 (UTC)", sortable: true, value: "sampled_at"},
      ],
      showLogOutConfirm: false,
      logoutTitle: "再認証が必要です。",
      showError: false,
      errorText: ""
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    fetchRecords(startDate, endDate) {
      this.loading = true
      var lineUserId = ''
      var token = ''
      var url = ''
      if (process.env.VUE_APP_DEPLOY_MODE == "PROD") {
        lineUserId = store.state.auth.lineUser.sub
        token = store.state.auth.lineAccessToken
        url = process.env.VUE_APP_API_ORIGIN + "/api/line_records/get?account_id=" + lineUserId
      } else {
        url = process.env.VUE_APP_API_ORIGIN + "/api/line_records_mock/get"
      }
      if (startDate) {
        startDate = startDate.replace("-", "").replace("-", "")
        url = url + "&start_date=" + startDate
      }
      if (endDate) {
        endDate = endDate.replace("-", "").replace("-", "")
        url = url + "&end_date=" + endDate
      }
      axios({
        method: "GET",
        url: url,
        withCredentials: true,
        headers: {
          "x-line-access-token": token
        },
        timeout: parseInt(process.env.VUE_APP_API_TIMEOUT_MILLISEC)
      })
      .then(res => {
        this.loading = false
        this.records = res.data
      })
      .catch(error => {
        this.loading = false
        if (error.response) {
          if (error.response.status == 403) {
            this.$store.dispatch('logout')
          } else {
            this.errorText = error.response.data.detail
            this.showError = true
          }
        } else {
          this.errorText = "ネットワーク接続を確認してください"
          this.showError = true
        }
      });
    },
    onRecordClick(r) {
      this.showDataDetail = true
      this.selectedRecord = r
    },
    closeDataDetail() {
      this.showDataDetail = false
    },
    onSearchClick() {
      this.fetchRecords(this.startDate, this.endDate)
    },
    closeLogOutConfirm() {
      this.showLogOutConfirm = false
    }
  },
  created() {
    this.fetchRecords()
  },
  mounted() {
  }
}
</script>

<style lang="css" scoped>
#download-btn{
  font-size: 0.8rem;
  color: #ffffff;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  padding: 0.5rem 2rem;
  background-color: #eb6100;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 0.1em;
  border-radius: 0.3rem;
}
#rs-table {
  margin-bottom: 10vh;
}
</style>
